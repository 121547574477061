@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

/* Watermark */
body {
    font-family: 'Raleway', sans-serif;
    margin: 0;
    padding: 0;
    background: #1b1f3a url('./assets/BETA-Logo1.png') no-repeat center;
    background-size: 1500px;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
}

/* Make the watermark more subtle */
body::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 31, 58, 0.8);
    z-index: -1;
}

.container {
    padding: 20px;
}

.header {
    text-align: center;
    margin-bottom: 40px;
    padding: 40px;
    background: linear-gradient(135deg, #2b2f5b, #1b1f3a);
    color: #fff;
}

.header h1 {
    font-size: 48px;
    font-weight: 700;
}

.header p {
    font-size: 20px;
    color: #c0c0c0;
}

.section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 40px;
    border-radius: 10px;
    opacity: 90%;
}

.section h2 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #fff;
}

.section p {
    font-size: 18px;
    /* color: #e5e5e5; */
    color: #ffffff;
    line-height: 1.6;
}

.section .content {
    flex: 1;
    padding-right: 20px;
}

.section .section-image {
    flex: 1;
    max-width: 500px;
    height: auto;
    /* width: 100px; */
    border-radius: 10px;
}

.section .section-image-android-web {
    width: 200px;
    /* Set a fixed width and height */
    height: 200px;
    border-radius: 50%;
    /* Makes the image circular */
    object-fit: cover;
    /* Ensures the image doesn't get stretched */
    margin-bottom: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.welcome {
    background: linear-gradient(135deg, #35408f, #2b2f5b);
}

.what-we-do {
    background: linear-gradient(135deg, #23274d, #1b1f3a);
}

.web-applications {
    background: linear-gradient(135deg, #2c3060, #1b1f3a);
}

.mobile-applications {
    background: linear-gradient(135deg, #3a3e74, #1b1f3a);
}

.contact {
    background: #464b8b;
    padding: 40px 20px;
    border-radius: 8px;
    text-align: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.contact-form .form-group {
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #f0f0f0;
    font-size: 16px;
    color: #333;
}

.contact-form button {
    background-color: #ff4081;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.contact-form button:hover {
    background-color: #e53670;
}

.contact-info {
    margin-top: 20px;
    color: #ddd;
}

@media (max-width: 768px) {
    .section {
        flex-direction: column;
    }

    .section .content {
        padding-right: 0;
    }

    .section-image {
        width: 100%;
        max-width: none;
        margin-top: 20px;
    }

    .header h1 {
        font-size: 32px;
    }

    .header p {
        font-size: 16px;
    }

    .section h2 {
        font-size: 24px;
    }

    .section p {
        font-size: 16px;
    }
}

/* Section heading */
.section.contact h2 {
    font-size: 28px;
    text-align: center;
    color: #ff4081;
    margin-bottom: 20px;
}

/* Improved aesthetic text */
.contact-description {
    background: linear-gradient(90deg, rgba(255, 64, 129, 0.2), rgba(70, 75, 139, 0.2));
    border-left: 5px solid #ff4081;
    border-right: 5px solid #ff4081;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    color: #f0f0f0;
    font-size: 18px;
    margin-bottom: 40px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Contact form */
.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border 0.3s;
}

.contact-form input:focus,
.contact-form textarea:focus {
    border: 1px solid #ff4081;
    outline: none;
}

.contact-form button {
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    background-color: #ff4081;
    color: white;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-form button:hover {
    background-color: #ff5a99;
}

/* Contact information styling */
.contact-info {
    text-align: center;
    color: #d3d3d3;
    margin-top: 20px;
    font-size: 16px;
}

/* Footer Section */
.footer {
    background-color: #1b1f3a;
    text-align: center;
    padding: 20px 0;
    color: #fff;
    font-size: 14px;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer p {
    margin: 0;
    font-weight: 300;
}

.footer p a {
    color: #ff4081;
    text-decoration: none;
}

.footer p a:hover {
    text-decoration: underline;
}